<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Avainten hallinta</div>
    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="tab">
          <v-btn value="0"> Avaimet</v-btn>
          <v-btn value="1"> Luo avaimia </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- ADD KEYS -->
    <Keys v-if="tab == 0" submit-btn-text="Liitä avaimet"></Keys>

    <!-- FORM -->
    <create-keys v-if="tab == 1" submit-btn-text="Luo avaimet"></create-keys>
  </div>
</template>

<script>
import CreateKeys from "../../components/Keys/CreateKeys";
import Keys from "../../views/Keys/Keys";
import mixins from "../../mixins/mixins";

export default {
  components: {
    CreateKeys,
    Keys,
  },

  mixins: [mixins],

  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style scoped></style>
