<template>
  <v-card width="300">
    <v-card-title class="white--text info">
      Luotavat avaimet
      <v-spacer></v-spacer>
    </v-card-title>

    <v-virtual-scroll :items="items" :item-height="50" height="310">
      <template v-slot="{ item }">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar size="80" color="primary" class="white--text">
              {{ item.idx }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.code }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
export default {
  props: {
    keysObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      keys: {
        from: 0,
        to: 1,
        suffix: "",
        prefix: "",
      },
    };
  },

  watch: {
    keysObj: {
      deep: true,
      handler(val) {
        this.keys = val || {
          from: 0,
          to: 1,
          suffix: "",
          prefix: "",
        };
      },
    },

    items: {
      deep: true,
      handler() {
        this.$store.commit("keys/addKeyScrollerKeys", this.items);
      },
    },
  },

  created() {
    this.$store.commit("keys/addKeyScrollerKeys", [{ idx: 1, code: 1 }]);
  },

  computed: {
    items() {
      let arrayLength = this.keys.to - this.keys.from;

      if (arrayLength > 1000) arrayLength = 1000;

      return Array.from({ length: arrayLength }, (v, idx) => {
        return {
          idx: idx + this.keys.from + 1,
          code: this.keys.prefix + (idx + this.keys.from + 1) + this.keys.suffix,
        };
      });
    },
  },
};
</script>
