<template>
  <div class="py-3 min-w-0 w-full">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          dark
          class="ml-2"
          @click="editMultipleDialog = selected.length > 0 ? true : false"
          >Muokkaa valittuja</v-btn
        >
      </v-card-title>
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              label="Avain, säilytys, tila..."
              single-line
              hide-details
              append-icon="mdi-magnify"
              @keyup.enter="
                getDataFromApi();
                apartmentSearch = '';
                keyHolderSearch = '';
                condominiumSearch = '';
                relatedContractSearch = '';
              "
              @click:append="
                getDataFromApi();
                apartmentSearch = '';
                keyHolderSearch = '';
                condominiumSearch = '';
                relatedContractSearch = '';
              "
              solo
              class="pr-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="condominiumSearch"
              label="Taloyhtiö / Kiinteistö"
              dense
              outlined
              class="pr-2"
              append-icon="mdi-magnify"
              @keyup.enter="
                searchByName('condominium');
                apartmentSearch = '';
                keyHolderSearch = '';
                relatedContractSearch = '';
              "
              @click:append="
                searchByName('condominium');
                apartmentSearch = '';
                keyHolderSearch = '';
                relatedContractSearch = '';
              "
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="apartmentSearch"
              style="font-size: 13px; font-weight: 400"
              label="Vuokrakohde"
              dense
              outlined
              class="pr-2"
              append-icon="mdi-magnify"
              @keyup.enter="
                searchByName('apartment');
                condominiumSearch = '';
                keyHolderSearch = '';
                relatedContractSearch = '';
              "
              @click:append="
                searchByName('apartment');
                condominiumSearch = '';
                keyHolderSearch = '';
                relatedContractSearch = '';
              "
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="keyHolderSearch"
              style="font-size: 13px; font-weight: 400"
              label="Haltija"
              dense
              outlined
              class="pr-2"
              append-icon="mdi-magnify"
              @keyup.enter="
                searchByName('keyHolder');
                apartmentSearch = '';
                condominiumSearch = '';
                relatedContractSearch = '';
              "
              @click:append="
                searchByName('keyHolder');
                apartmentSearch = '';
                condominiumSearch = '';
                relatedContractSearch = '';
              "
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="relatedContractSearch"
              style="font-size: 13px; font-weight: 400"
              label="Sopimuksen numero"
              dense
              outlined
              class="pr-2"
              append-icon="mdi-magnify"
              @keyup.enter="
                searchByName('relatedContract');
                keyHolderSearch = '';
                apartmentSearch = '';
                condominiumSearch = '';
              "
              @click:append="
                searchByName('relatedContract');
                keyHolderSearch = '';
                apartmentSearch = '';
                condominiumSearch = '';
              "
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        :items="keys"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        item-key="code"
        :page.sync="pagination"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Code -->
        <template #[`item.code`]="{ item }">
          <span class="font-weight-bold"
            ><v-icon small>mdi-key-variant</v-icon> {{ item.code }}</span
          >
        </template>

        <!-- Status -->
        <template #[`item.status`]="{ item }">
          <span v-if="item.status == `Vapaa`" class="font-weight-bold success--text">{{
            item.status
          }}</span>
          <span v-if="item.status == `Poistunut`" class="font-weight-bold error--text">{{
            item.status
          }}</span>
          <span v-if="item.status == `Käytössä`" class="font-weight-bold warning--text">{{
            item.status
          }}</span>
        </template>

        <!-- Overview link keyholder -->
        <template #[`item.keyHolders`]="{ item }">
          <router-link
            v-if="item.keyHolders.length > 0"
            style="text-decoration: none"
            :to="`/tenant/overview/${item.keyHolders[0]._id}`"
          >
            <!-- Active keyholder -->
            <span v-if="item.keyHolders[0].active" class="primary--text">
              {{ item.keyHolders[0].name }}
            </span>
            <!-- InActive keyholder -->
            <span v-if="!item.keyHolders[0].active" class="warning--text">
              {{ item.keyHolders[0].name }} (poistettu)
            </span>
          </router-link>
          <span v-if="!item.keyHolders[0]">Ei haltijaa</span>
        </template>

        <!-- Related Contracts -->
        <template #[`item.relatedContracts`]="{ item }">
          <span v-if="item.keyHolders.length == 0">Ei sopimuksia</span>

          <div v-if="item.keyHolders[0]">
            <div v-if="item.keyHolders[0].relatedContracts.length == 0">Ei sopimuksia</div>

            <div v-if="item.keyHolders[0].relatedContracts.length > 0">
              <span
                v-for="(contract, index) in showValidContracts(item.keyHolders[0].relatedContracts)"
                :key="index + 'a'"
              >
                <router-link
                  style="text-decoration: none"
                  :to="`/rental-contract/overview/${contract._id}`"
                >
                  <span :class="['font-weight-bold', getContractStateColor(contract.state)]">
                    {{ contract.contractNumber }}
                  </span>
                </router-link>
                <span> ({{ contract.tenant.name }}) </span>
                <span
                  v-if="index != showValidContracts(item.keyHolders[0].relatedContracts).length - 1"
                >
                  |
                </span>
              </span>
            </div>
          </div>
        </template>

        <!-- Removed Contracts -->
        <template #[`item.removedRelatedContracts`]="{ item }">
          <span v-if="item.keyHolders.length == 0">Ei sopimuksia</span>

          <div v-if="item.keyHolders[0]">
            <div v-if="item.keyHolders[0].removedRelatedContracts.length == 0">Ei sopimuksia</div>

            <div v-if="item.keyHolders[0].removedRelatedContracts.length > 0">
              <span
                v-for="(contract, index) in showValidContracts(
                  item.keyHolders[0].removedRelatedContracts
                )"
                :key="index + 'a'"
              >
                <router-link
                  style="text-decoration: none"
                  :to="`/rental-contract/overview/${contract._id}`"
                  ><span
                    style="
                      border-bottom: 2px solid rgb(248, 139, 139);
                      padding: 1px 2px 1px 2px;
                      border-radius: 2px;
                    "
                    :class="['font-weight-bold', getContractStateColor(contract.state)]"
                  >
                    {{ contract.contractNumber }}
                  </span>
                </router-link>
                <span>({{ contract.tenant.name }})</span>
                <span
                  v-if="
                    index !=
                    showValidContracts(item.keyHolders[0].removedRelatedContracts).length - 1
                  "
                >
                  |
                </span>
              </span>
            </div>
          </div>
        </template>

        <!-- Apartments -->
        <template #[`item.apartments`]="{ item }">
          <span v-for="(el, idx) in item.apartments" :key="idx + 'a'">
            <span>{{ el.address }}</span
            ><span>{{ el.apartmentNumber ? `, ${el.apartmentNumber}` : "" }} </span>
            <br />
          </span>
        </template>

        <!-- condominiums -->
        <template #[`item.condominiums`]="{ item }">
          <span v-for="(el, idx) in item.condominiums" :key="idx + 'b'">{{ el.name }}<br /> </span>
        </template>

        <!-- Manual signature -->
        <template #[`item.manualSignature`]="{ item }">
          <span v-if="item.manualSignature" class="font-weight-bold success--text"> Kuitattu </span>
          <span v-else class="font-weight-bold error--text">Ei kuitattu</span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              title="Muokkaa"
              small
              @click="
                $store.state.keys.key = { ...item };
                editSingleDialog = true;
              "
              >mdi-pencil</v-icon
            >

            <v-icon title="Poista" small @click="deleteItem(item)">mdi-delete</v-icon>
          </div>
        </template>
        <template #no-data>
          <h2>Ei avaimia</h2>
        </template>
      </v-data-table>
    </v-card>

    <!--MULTIPLE DIALOG -->
    <edit-multiple-dialog
      v-model="editMultipleDialog"
      @sendedititems="editMultiple"
    ></edit-multiple-dialog>

    <!--  EDIT SINGLE KEY -->
    <edit-single-dialog v-model="editSingleDialog"></edit-single-dialog>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import EditMultipleDialog from "../../components/Keys/EditMultipleDialog";
import EditSingleDialog from "../../components/Keys/EditSingleDialog";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Hallitse avaimia",

  components: {
    EditMultipleDialog,
    EditSingleDialog,
  },

  mixins: [mixins],

  data: () => ({
    loading: true,
    pagination: 1,
    selected: [],
    options: {},
    keyHolderSearch: "",
    apartmentSearch: "",
    condominiumSearch: "",
    relatedContractSearch: "",
    editSingleDialog: false,
    editMultipleDialog: false,
    search: "",
    headers: [
      { text: "Avain", value: "code" },
      { text: "Haltija", value: "keyHolders", sortable: false },
      { text: "Haltija aktiivinen sop.", value: "relatedContracts", sortable: false },
      { text: "Haltija poistettu sop.", value: "removedRelatedContracts", sortable: false },
      { text: "Kiinteistö", value: "condominiums" },
      { text: "Kohde", value: "apartments" },
      { text: "Säilytys", value: "location" },
      { text: "Tila", value: "status" },
      { text: "Kuittaus", value: "manualSignature" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    condominium: {},
    condominiums: [],
    globalValues,
  }),

  computed: {
    ...mapState("keys", ["keys", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
      "searchField",
      "searchValue",
    ]),
  },

  watch: {
    options: {
      deep: true,
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          if (this.searchField == "condominium") this.condominiumSearch = this.searchValue;
          if (this.searchField == "apartment") this.apartmentSearch = this.searchValue;
          if (this.searchField == "keyHolder") this.keyHolderSearch = this.searchValue;
          if (this.searchField == "relatedContract") this.relatedContractSearch = this.searchValue;

          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }

        this.saveTableOptions({ ...this.options });

        if (this.keyHolderSearch != "") {
          this.searchByName("keyHolder");
        } else if (this.condominiumSearch != "") {
          this.searchByName("condominium");
        } else if (this.apartmentSearch != "") {
          this.searchByName("apartment");
        } else if (this.relatedContractSearch != "") {
          this.searchByName("relatedContract");
        } else {
          this.getDataFromApi();
        }
      },
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("keys", ["getAllKeys"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveSearchTerm", "saveMainSearchTerm"]),

    isSortable() {
      if (
        this.keyHolderSearch == "" &&
        this.condominiumSearch == "" &&
        this.apartmentSearch == "" &&
        this.relatedContractSearch == ""
      )
        return true;
      else return false;
    },

    async getDataFromApi() {
      this.loading = true;
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      await this.getAllKeys(
        `/api/v1/key/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
      );

      this.loading = false;
    },

    async searchByName(name) {
      try {
        this.loading = true;
        let { page, itemsPerPage, sortBy, sortDesc } = this.options;
        let search;
        let additionalSearch = this.search.trim().toLowerCase();
        let url;

        // Save main search term
        this.saveMainSearchTerm(this.search);

        if (name === "condominium") {
          this.saveSearchTerm({ field: "condominium", val: this.condominiumSearch });
          search = this.condominiumSearch.trim().toLowerCase();

          url = `/api/v1/key/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&condominiumSearch=${search}&additionalSearch=${additionalSearch}`;
        } else if (name === "apartment") {
          this.saveSearchTerm({ field: "apartment", val: this.apartmentSearch });
          search = this.apartmentSearch.trim().toLowerCase();

          url = `/api/v1/key/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&apartmentSearch=${search}&additionalSearch=${additionalSearch}`;
        } else if (name === "keyHolder") {
          this.saveSearchTerm({ field: "keyHolder", val: this.keyHolderSearch });
          search = this.keyHolderSearch.trim().toLowerCase();

          url = `/api/v1/key/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&keyHolderSearch=${search}&additionalSearch=${additionalSearch}`;
        } else if (name === "relatedContract") {
          this.saveSearchTerm({ field: "relatedContract", val: this.relatedContractSearch });
          search = this.relatedContractSearch.trim().toLowerCase();

          url = `/api/v1/key/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&relatedContractSearch=${search}&additionalSearch=${additionalSearch}`;
        }

        await this.getAllKeys(url);

        this.loading = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async editMultiple(values) {
      try {
        if (values.deleteAll) {
          let res = await axiosMethods.delete("/api/v1/key/deleteMany/", {
            values,
            selected: this.selected,
          });

          res.data.deletedKeys.forEach((el) => {
            const index = this.keys.findIndex((x) => x._id === el._id);
            this.keys.splice(index, 1);
          });

          this.showPopup("Avaimet poistettu", "success");
        } else {
          let res = await axiosMethods.update("/api/v1/key/updateMany/", {
            values,
            selected: this.selected,
          });

          res.data.keys.forEach((el) => {
            this.keys.splice(
              this.keys.findIndex((x) => x._id === el._id),
              1,
              el
            );
          });

          this.showPopup("Avaimet päivitetty", "success");
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa avaimen: " + item.code)) {
        try {
          await axiosMethods.delete("/api/v1/key/delete/" + item._id);

          const index = this.keys.findIndex((x) => x._id === item._id);
          this.keys.splice(index, 1);

          this.showPopup("Avain poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    showValidContracts(contracts) {
      return contracts.filter((el) => {
        if (el.state !== "Päättynyt") {
          return el;
        }
      });
    },
  },
};
</script>

<style scoped></style>
