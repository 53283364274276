var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.editMultipleDialog = _vm.selected.length > 0 ? true : false}}},[_vm._v("Muokkaa valittuja")])],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"pr-2",attrs:{"label":"Avain, säilytys, tila...","single-line":"","hide-details":"","append-icon":"mdi-magnify","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.getDataFromApi();
              _vm.apartmentSearch = '';
              _vm.keyHolderSearch = '';
              _vm.condominiumSearch = '';
              _vm.relatedContractSearch = '';},"click:append":function($event){_vm.getDataFromApi();
              _vm.apartmentSearch = '';
              _vm.keyHolderSearch = '';
              _vm.condominiumSearch = '';
              _vm.relatedContractSearch = '';}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{staticClass:"pr-2",attrs:{"label":"Taloyhtiö / Kiinteistö","dense":"","outlined":"","append-icon":"mdi-magnify","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchByName('condominium');
              _vm.apartmentSearch = '';
              _vm.keyHolderSearch = '';
              _vm.relatedContractSearch = '';},"click:append":function($event){_vm.searchByName('condominium');
              _vm.apartmentSearch = '';
              _vm.keyHolderSearch = '';
              _vm.relatedContractSearch = '';}},model:{value:(_vm.condominiumSearch),callback:function ($$v) {_vm.condominiumSearch=$$v},expression:"condominiumSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{staticClass:"pr-2",staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"label":"Vuokrakohde","dense":"","outlined":"","append-icon":"mdi-magnify","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchByName('apartment');
              _vm.condominiumSearch = '';
              _vm.keyHolderSearch = '';
              _vm.relatedContractSearch = '';},"click:append":function($event){_vm.searchByName('apartment');
              _vm.condominiumSearch = '';
              _vm.keyHolderSearch = '';
              _vm.relatedContractSearch = '';}},model:{value:(_vm.apartmentSearch),callback:function ($$v) {_vm.apartmentSearch=$$v},expression:"apartmentSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{staticClass:"pr-2",staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"label":"Haltija","dense":"","outlined":"","append-icon":"mdi-magnify","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchByName('keyHolder');
              _vm.apartmentSearch = '';
              _vm.condominiumSearch = '';
              _vm.relatedContractSearch = '';},"click:append":function($event){_vm.searchByName('keyHolder');
              _vm.apartmentSearch = '';
              _vm.condominiumSearch = '';
              _vm.relatedContractSearch = '';}},model:{value:(_vm.keyHolderSearch),callback:function ($$v) {_vm.keyHolderSearch=$$v},expression:"keyHolderSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{staticClass:"pr-2",staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"label":"Sopimuksen numero","dense":"","outlined":"","append-icon":"mdi-magnify","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchByName('relatedContract');
              _vm.keyHolderSearch = '';
              _vm.apartmentSearch = '';
              _vm.condominiumSearch = '';},"click:append":function($event){_vm.searchByName('relatedContract');
              _vm.keyHolderSearch = '';
              _vm.apartmentSearch = '';
              _vm.condominiumSearch = '';}},model:{value:(_vm.relatedContractSearch),callback:function ($$v) {_vm.relatedContractSearch=$$v},expression:"relatedContractSearch"}})],1)],1)],1),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.keys,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"item-key":"code","page":_vm.pagination,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:page":function($event){_vm.pagination=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-key-variant")]),_vm._v(" "+_vm._s(item.code))],1)]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.status == "Vapaa")?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status == "Poistunut")?_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status == "Käytössä")?_c('span',{staticClass:"font-weight-bold warning--text"},[_vm._v(_vm._s(item.status))]):_vm._e()]}},{key:"item.keyHolders",fn:function(ref){
              var item = ref.item;
return [(item.keyHolders.length > 0)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item.keyHolders[0]._id))}},[(item.keyHolders[0].active)?_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.keyHolders[0].name)+" ")]):_vm._e(),(!item.keyHolders[0].active)?_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(item.keyHolders[0].name)+" (poistettu) ")]):_vm._e()]):_vm._e(),(!item.keyHolders[0])?_c('span',[_vm._v("Ei haltijaa")]):_vm._e()]}},{key:"item.relatedContracts",fn:function(ref){
              var item = ref.item;
return [(item.keyHolders.length == 0)?_c('span',[_vm._v("Ei sopimuksia")]):_vm._e(),(item.keyHolders[0])?_c('div',[(item.keyHolders[0].relatedContracts.length == 0)?_c('div',[_vm._v("Ei sopimuksia")]):_vm._e(),(item.keyHolders[0].relatedContracts.length > 0)?_c('div',_vm._l((_vm.showValidContracts(item.keyHolders[0].relatedContracts)),function(contract,index){return _c('span',{key:index + 'a'},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (contract._id))}},[_c('span',{class:['font-weight-bold', _vm.getContractStateColor(contract.state)]},[_vm._v(" "+_vm._s(contract.contractNumber)+" ")])]),_c('span',[_vm._v(" ("+_vm._s(contract.tenant.name)+") ")]),(index != _vm.showValidContracts(item.keyHolders[0].relatedContracts).length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()],1)}),0):_vm._e()]):_vm._e()]}},{key:"item.removedRelatedContracts",fn:function(ref){
              var item = ref.item;
return [(item.keyHolders.length == 0)?_c('span',[_vm._v("Ei sopimuksia")]):_vm._e(),(item.keyHolders[0])?_c('div',[(item.keyHolders[0].removedRelatedContracts.length == 0)?_c('div',[_vm._v("Ei sopimuksia")]):_vm._e(),(item.keyHolders[0].removedRelatedContracts.length > 0)?_c('div',_vm._l((_vm.showValidContracts(
                item.keyHolders[0].removedRelatedContracts
              )),function(contract,index){return _c('span',{key:index + 'a'},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (contract._id))}},[_c('span',{class:['font-weight-bold', _vm.getContractStateColor(contract.state)],staticStyle:{"border-bottom":"2px solid rgb(248, 139, 139)","padding":"1px 2px 1px 2px","border-radius":"2px"}},[_vm._v(" "+_vm._s(contract.contractNumber)+" ")])]),_c('span',[_vm._v("("+_vm._s(contract.tenant.name)+")")]),(
                  index !=
                  _vm.showValidContracts(item.keyHolders[0].removedRelatedContracts).length - 1
                )?_c('span',[_vm._v(" | ")]):_vm._e()],1)}),0):_vm._e()]):_vm._e()]}},{key:"item.apartments",fn:function(ref){
                var item = ref.item;
return _vm._l((item.apartments),function(el,idx){return _c('span',{key:idx + 'a'},[_c('span',[_vm._v(_vm._s(el.address))]),_c('span',[_vm._v(_vm._s(el.apartmentNumber ? (", " + (el.apartmentNumber)) : "")+" ")]),_c('br')])})}},{key:"item.condominiums",fn:function(ref){
                var item = ref.item;
return _vm._l((item.condominiums),function(el,idx){return _c('span',{key:idx + 'b'},[_vm._v(_vm._s(el.name)),_c('br')])})}},{key:"item.manualSignature",fn:function(ref){
                var item = ref.item;
return [(item.manualSignature)?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(" Kuitattu ")]):_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v("Ei kuitattu")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.$store.state.keys.key = Object.assign({}, item);
              _vm.editSingleDialog = true;}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei avaimia")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('edit-multiple-dialog',{on:{"sendedititems":_vm.editMultiple},model:{value:(_vm.editMultipleDialog),callback:function ($$v) {_vm.editMultipleDialog=$$v},expression:"editMultipleDialog"}}),_c('edit-single-dialog',{model:{value:(_vm.editSingleDialog),callback:function ($$v) {_vm.editSingleDialog=$$v},expression:"editSingleDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }