<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title> Muokkaa avainta {{ key.code }} </v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="6">
              <h3 class="mb-2">Avaimen nimi</h3>
              <v-text-field
                v-model="currentKey.code"
                :rules="required"
                outlined
                dense
                label="Avaimen nimi"
                append-icon="mdi-key-variant"
              >
              </v-text-field>
            </v-col>
            <v-col v-if="$route.name != 'Vuokralaisen hallinta'" cols="12" sm="6">
              <h3 class="mb-2">Haltija</h3>
              <v-autocomplete
                v-model="currentKey.keyHolder"
                :items="keyHolderTenants"
                item-text="name"
                item-value="_id"
                label="Haltija"
                outlined
                dense
                return-object
                @click.capture="keyHolderChange"
                @focus="getAllTenants({ url: '/api/v1/tenant/getAll/', value: 'keyHolders' })"
                :search-input.sync="searchInput1"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <h3 class="mb-2">Oikeudet</h3>
          <v-autocomplete
            v-model="currentKey.condominiums"
            :items="condominiumsArr"
            item-text="name"
            item-value="_id"
            label="Taloyhtiö / kiinteistö"
            multiple
            small-chips
            deletable-chips
            outlined
            return-object
            @change="
              updateApartments();
              searchInput2 = '';
            "
            @focus="getCondominiums()"
            :search-input.sync="searchInput2"
          ></v-autocomplete>

          <v-autocomplete
            v-model="currentKey.apartments"
            :items="apartmentsArr"
            item-text="fullAddress"
            item-value="_id"
            multiple
            small-chips
            deletable-chips
            outlined
            return-object
            label="Vuokrakohde (ei pakollinen)"
            @focus="getApartments()"
            :search-input.sync="searchInput3"
            @change="searchInput3 = ''"
          ></v-autocomplete>

          <h3 class="mb-2">Muut</h3>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                v-model="currentKey.status"
                dense
                outlined
                :items="statuses"
                label="Status"
                @change="controlKeyHolder($event)"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-textarea v-model="currentKey.comments" rows="2" outlined dense label="Kommentit">
          </v-textarea>

          <v-row v-if="$route.name != 'Vuokralaisen hallinta'" dense>
            <v-col sm="6" cols="12">
              <v-text-field
                v-model="currentKey.location"
                outlined
                dense
                label="Säilytyspaikka"
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>Manuaalinen luovutuskuittaus</h3>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-radio-group
                v-model="currentKey.manualSignature"
                :mandatory="true"
                style="margin-top: 0px"
              >
                <v-radio @click.capture="signedChange" label="Kuitattu" :value="true"></v-radio>
                <v-radio @click.capture="signedChange" label="Ei kuitattu" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="editOne()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog -->
    <v-dialog v-model="keyHolderDialog" width="500">
      <v-card>
        <v-card-title v-if="currentKey.status == 'Poistunut'">
          Status muutetaan poistuneeksi, mutta haltijaa ja kuittausta ei poisteta. Haluatko jatkaa?"
        </v-card-title>

        <v-card-title v-else
          >Status muutetaan vapaaksi, haltija ja kuittaus poistetaan. Haluatko jatkaa?</v-card-title
        >

        <v-card-actions>
          <v-btn small color="error" outlined @click="rejected">Hylkää</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="confirmed">Hyväksy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      searchInput1: "",
      searchInput2: "",
      searchInput3: "",
      currentKey: {
        manualSignature: false,
        code: "",
        apartments: [],
        condominiums: [],
        status: "",
        comments: "",
        location: "",
        keyHolders: [],
        keyHolder: {},
      },
      keyHolderDialog: false,
      currentStatus: "",
      apartmentsArr: [],
      condominiumsArr: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("keys", ["key"]),
    ...mapState("tenant", ["keyHolderTenants"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    statuses() {
      if (this.$route.name == "Vuokralaisen hallinta") {
        return ["Käytössä", "Poistunut"];
      } else {
        return ["Vapaa", "Poistunut", "Käytössä"];
      }
    },
  },

  watch: {
    key(item) {
      // set current status of key
      this.currentStatus = item.status;
      // Set key obj
      this.currentKey = { ...item };

      // Current keyHolder to be seen in v-select at start
      if (item.keyHolders) {
        if (item.keyHolders.length > 0) {
          this.currentKey.keyHolder = { ...item.keyHolders[0] };
          this.$store.state.tenant.keyHolderTenants.push({ ...item.keyHolders[0] });
        } else {
          this.currentKey.keyHolder = {};
        }
      }
    },

    "currentKey.condominiums": {
      deep: true,
      handler() {
        if (this.currentKey.condominiums.length == 0) {
          this.apartmentsArr = [];
          this.currentKey.apartments = [];
        } else {
          this.getApartments();
          this.deleteNotRelatedApartments();
        }
      },
    },
  },

  async created() {
    await this.getCondominiums();
  },

  methods: {
    ...mapActions("keys", ["editSingleKey", "editSingleTenantKey"]),
    ...mapActions("tenant", ["getAllTenants"]),

    async editOne() {
      if (this.$refs.form.validate()) {
        if (this.$route.name == "Vuokralaisen hallinta") {
          this.editSingleTenantKey({ ...this.currentKey });
        } else {
          this.editSingleKey({ ...this.currentKey });
        }
      }
    },

    deleteNotRelatedApartments() {
      let currentCondominiumIds = this.currentKey.condominiums.map((el) => {
        return el._id;
      });

      this.currentKey.apartments = this.currentKey.apartments.filter((el) => {
        return currentCondominiumIds.includes(el.condominium._id);
      });
    },

    async getCondominiums() {
      try {
        let res = await axiosMethods.post("/api/v1/condominium/getAll");
        this.condominiumsArr = res.data.condominiums;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async getApartments() {
      if (this.currentKey.condominiums.length > 0) {
        const condominiumIds = this.currentKey.condominiums.map((el) => el._id);
        try {
          let res1 = await axiosMethods.post("/api/v1/apartment/getByCondominiums/", {
            condominiumIds,
          });

          let apartments = res1.data.apartments;

          apartments.map((el) => {
            el.fullAddress = el.apartmentNumber
              ? `${el.address}, ${el.apartmentNumber}, ${el.zipCode}, ${el.city}`
              : `${el.address}, ${el.zipCode}, ${el.city}`;
            return el;
          });

          this.apartmentsArr = apartments;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    updateApartments() {
      let apartments = [];
      const condominiumIds = this.key.condominiums.map((el) => el._id);

      this.key.apartments.forEach((el) => {
        if (condominiumIds.includes(el.condominium._id)) {
          apartments.push(el);
        }
      });

      this.apartments = apartments;
    },

    controlKeyHolder(status) {
      if (status == "Vapaa" || status == "Poistunut") {
        this.keyHolderDialog = true;
      }
    },

    confirmed() {
      if (this.currentKey.status == "Vapaa") {
        this.currentKey.keyHolder = null;
        this.currentKey.manualSignature = false;
      }
      this.currentStatus = this.currentKey.status;
      this.keyHolderDialog = false;
    },

    rejected() {
      this.currentKey.status = this.currentStatus;
      this.keyHolderDialog = false;
    },

    signedChange(event) {
      if (!confirm("Avaimen luovutuksen statusta ollaan muuttamassa, haluatko varmasti jatkaa?")) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    keyHolderChange(event) {
      if (confirm("Kun muutat haltijaa, kuittaus nollataan? Jatketaanko?")) {
        this.currentStatus = "Käytössä";
        this.currentKey.status = "Käytössä";
        this.searchInput1 = "";
        this.currentKey.manualSignature = false;
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style scoped>
.information-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px 15px 20px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.information-item {
  text-align: center;
  padding: 10px;
}

@media only screen and (max-width: 550px) {
  .information-box {
    flex-direction: column;
  }
}
</style>
