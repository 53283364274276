<template>
  <v-card class="mt-2">
    <v-card-title>Luo avaimia</v-card-title>
    <v-container>
      <v-row dense>
        <v-col cols="12" lg="6">
          <v-row dense>
            <v-col md="5">
              <v-checkbox v-model="createOne" label="Luo yksittäinen avain"></v-checkbox>
            </v-col>
          </v-row>
          <v-form ref="form">
            <!-- Create Multiple -->
            <div v-if="!createOne">
              <small>Juokseva numero; mistä numerosta mihin numeroon avaimet luodaan.</small>
              <div>
                <small class="error--text">Voit luoda maksimissaan 1000 avainta kerralla.</small>
              </div>
              <v-row class="mt-3" dense>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="keys.from"
                    outlined
                    dense
                    step="1"
                    type="number"
                    label="Mistä"
                    min="0"
                    max="1000"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="keys.to"
                    outlined
                    dense
                    step="1"
                    type="number"
                    label="Mihin"
                    max="1000"
                    min="0"
                    :rules="positiveNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <small>Valinnaiset avainten etu- ja/tai takaliitteet.</small>
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="keys.prefix"
                    outlined
                    dense
                    label="Etuliite"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="keys.suffix"
                    outlined
                    dense
                    label="Takaliite"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- CreateOne -->

            <v-row v-if="createOne" class="mt-3" dense>
              <v-col cols="6" md="5" sm="6">
                <v-text-field
                  v-model="key"
                  outlined
                  dense
                  label="Avainkoodi"
                  :rules="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Condominium -->
            <h3 class="mt-5">Kohdista taloyhtiöön / kiinteistöön</h3>
            <small>Avaimet on mahdollista kohdistaa myös niiden luomisen jälkeen</small>
            <v-row dense class="mt-2">
              <v-col md="6" lg="5" cols="10">
                <v-autocomplete
                  v-model="condominiums"
                  :items="condominiumsArr"
                  outlined
                  dense
                  item-text="name"
                  item-value="_id"
                  label="Taloyhtiö / kiinteistö"
                  multiple
                  small-chips
                  @focus="getCondominiums()"
                ></v-autocomplete>
              </v-col>
              <v-col md="6" lg="5" cols="10">
                <v-text-field
                  v-model="location"
                  label="Säilytyspaikka"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col v-if="!createOne" cols="12" lg="6">
          <key-scroller :keysObj="keys"></key-scroller>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn color="info" class="mt-5" @click="createKeys">{{ submitBtnText }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import KeyScroller from "../../components/Keys/KeyScroller";

export default {
  title: "Luo avaimia",

  components: {
    KeyScroller,
  },

  mixins: [mixins],

  props: {
    submitBtnText: { type: String, default: "" },
  },

  data() {
    return {
      createOne: false,
      condominiumsArr: [],
      condominiums: [],
      condominium: {},
      location: "",
      multiple: false,
      key: "",
      keys: {
        prefix: "",
        suffix: "",
        from: 0,
        to: 1,
      },
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => v > 0 || "Anna positiivinen luku"],
    };
  },

  watch: {
    createOne(boolean) {
      if (!boolean) {
        this.key = "";
      }
    },
  },

  methods: {
    async getCondominiums() {
      try {
        let res = await axiosMethods.post("/api/v1/condominium/getAll");
        this.condominiumsArr = res.data.condominiums;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async createKeys() {
      if (this.$refs.form.validate()) {
        try {
          if (this.createOne) {
            await axiosMethods.post(`/api/v1/key/create`, {
              keys: [{ code: this.key }],
              condominiums: this.condominiums,
              location: this.location,
            });
          } else {
            await axiosMethods.post(`/api/v1/key/create`, {
              keys: this.$store.state.keys.keyScrollerKeys,
              condominiums: this.condominiums,
              location: this.location,
            });
          }
          this.showPopup("Avaimet luotu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped></style>
