<template>
  <!-- Dialog Adding to do -->

  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Muokkaa valittuja avaimia </v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="form">
          <h3 v-if="!values.deleteAll" class="mb-2">Oikeudet</h3>
          <h3 v-if="values.deleteAll" class="mb-2">Poisto</h3>

          <div v-if="!values.deleteAll">
            <v-autocomplete
              v-model="values.condominiums"
              :items="condominiumsArr"
              item-text="name"
              item-value="_id"
              label="Taloyhtiö / kiinteistö"
              multiple
              outlined
              return-object
              small-chips
              @change="updateApartments()"
              @focus="getCondominiums()"
            ></v-autocomplete>

            <v-autocomplete
              v-model="values.apartments"
              :items="apartmentsArr"
              item-text="fullAddress"
              item-value="_id"
              multiple
              outlined
              small-chips
              return-object
              label="Vuokrakohde (ei pakollinen)"
              @focus="getApartments()"
            ></v-autocomplete>
          </div>

          <div v-if="!values.deleteAll">
            <h3 class="mb-2">Säilytys</h3>
            <v-text-field
              v-model="values.location"
              outlined
              dense
              label="Säilytyspaikka"
            ></v-text-field>
          </div>

          <v-checkbox v-model="values.deleteAll" label="Poista kaikki valitut"></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="editMany()">{{
          values.deleteAll ? "Poista valitut" : "Muokkaa valitut"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      values: {
        apartments: [],
        condominiums: [],
        location: "",
        deleteAll: false,
      },
      apartmentsArr: [],
      condominiumsArr: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.values.deleteAll = false;
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.values = {
          apartments: [],
          condominiums: [],
          location: "",
          deleteAll: false,
        };
      }
    },

    "values.condominiums": {
      deep: true,
      handler() {
        if (this.values.condominiums.length == 0) {
          this.apartmentsArr = [];
        }
      },
    },
  },

  methods: {
    editMany() {
      if (this.values.deleteAll) {
        if (confirm("Oletko varma, että haluat poistaa kaikki valitut avaimet?")) {
          this.$emit("sendedititems", { ...this.values });
        }
      } else {
        if (this.values.apartments.length == 0 && this.values.condominiums.length == 0) {
          if (
            confirm(
              "Jos taloyhtiö- tai vuokrakohdeoikeuksia ei ole valittu, avaimen kaikki oikeudet poistetaan muokattaessa. Jatketaanko?"
            )
          ) {
            this.$emit("sendedititems", { ...this.values });
          }
        } else {
          this.$emit("sendedititems", { ...this.values });
        }
      }
    },

    async getCondominiums() {
      try {
        let res = await axiosMethods.post("/api/v1/condominium/getAll");
        this.condominiumsArr = res.data.condominiums;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async getApartments() {
      if (this.values.condominiums.length > 0) {
        const condominiumIds = this.values.condominiums.map((el) => el._id);

        try {
          let res1 = await axiosMethods.post("/api/v1/apartment/getByCondominiums/", {
            condominiumIds,
          });

          let apartments = res1.data.apartments;

          apartments.map((el) => {
            el.fullAddress = el.apartmentNumber
              ? `${el.address}, ${el.apartmentNumber}, ${el.zipCode}, ${el.city}`
              : `${el.address}, ${el.zipCode}, ${el.city}`;
            return el;
          });

          this.apartmentsArr = apartments;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    updateApartments() {
      let apartments = [];
      const condominiumIds = this.values.condominiums.map((el) => el._id);

      this.values.apartments.forEach((el) => {
        if (condominiumIds.includes(el.condominium._id)) {
          apartments.push(el);
        }
      });

      this.values.apartments = apartments;
    },
  },
};
</script>

<style scoped></style>
